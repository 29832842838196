// 用于 select 的 mixin
export default {
  props: {
    // 选中项 value
    value: {
      type: [String, Number, Boolean, Array],
      default: '',
    },
    // 占位符文案
    placeholder: {
      type: String,
      default: '请选择',
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    // 是否可清空
    clearable: {
      type: Boolean,
      default: true,
    },
    // 是否可搜索
    filterable: {
      type: Boolean,
      default: true,
    },
    // 自定义搜索函数
    filterMethod: {
      type: Function,
      default: undefined,
    },
    // 是否允许创建新选项，需配合 filterable 使用
    allowCreate: {
      type: Boolean,
      default: false,
    },
    // 是否在搜索时按回车选中第一个匹配项
    defaultFirstOption: {
      type: Boolean,
      default: true,
    },
    // medium/small/mini
    size: {
      type: String,
      default: '',
    },
    // 作为 label 的 key
    labelKey: {
      type: String,
      default: 'label',
    },
    // 作为 value 的 key
    valueKey: {
      type: String,
      default: 'value',
    },
    // 是否默认选中第一项
    defaultSelectFirst: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      list: [],
      selected: '',
    };
  },
  computed: {
    // 克隆列表
    clonedList() {
      return JSON.parse(JSON.stringify(this.list || []));
    },
  },
  methods: {
    // 获取列表数据，供父组件调用
    getList() {
      return this.clonedList;
    },
    // 选中第一项
    selectFirstItem() {
      const firstItem = this.list[0] || {};
      const firstItemVal = firstItem[this.valueKey] || '';

      this.$emit('input', firstItemVal);
      this.$emit('on-change', firstItemVal);
    },
    // 选中项改变
    handleValueChange(value) {
      this.$emit('input', value);
      this.$emit('on-change', value, this.clonedList);
    },
    // 清空按钮单击
    handleValueClear() {
      this.$emit('on-clear');
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(n) {
        n !== this.selected && (this.selected = n);
      },
    },
  },
};
