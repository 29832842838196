// 合作状态
export default {
  map: {
    1: '已签约',
    2: '已排期',
    3: '已投放',
    4: '已完成',
  },
  enum: {
    signed: 1,
    scheduled: 2,
    published: 3,
    completed: 4,
  },
};
