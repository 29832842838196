<!-- cooperation-status -->
<template>
  <el-select
    v-model="selected"
    :placeholder="placeholder"
    :disabled="disabled"
    :clearable="clearable"
    :filterable="filterable"
    :filter-method="filterMethod"
    :default-first-option="defaultFirstOption"
    :size="size"
    @change="handleValueChange"
    @clear="handleValueClear"
  >
    <el-option
      v-for="item in list"
      :label="item[labelKey]"
      :value="item[valueKey]"
      :key="item[valueKey]"
    />
  </el-select>
</template>

<script>
  import select from '../../mixins/select';
  import cooperationStatus from '../../enum/cooperation-status';

  export default {
    name: 'cooperation-status',
    mixins: [select],
    props: {
      // 不展示的合作状态枚举值
      excludeStatus: {
        type: Number | Array,
        validator: val => {
          const statusArr = Array.isArray(val) ? val : [val];
          const statusMap = cooperationStatus.map;

          return !statusArr.some(status => !statusMap.hasOwnProperty(status));
        },
        default: () => [],
      },
    },
    mounted() {
      this.getCooperationStatus();
    },
    methods: {
      getCooperationStatus() {
        const { map } = cooperationStatus;
        const excludeStatus = this.excludeStatus;
        const excludeValues = Array.isArray(excludeStatus)
          ? excludeStatus
          : [excludeStatus];

        this.list = Object.entries(map)
          .map(([value, key]) => ({
            [this.labelKey]: key,
            [this.valueKey]: +value,
          }))
          .filter(item => !excludeValues.includes(item[this.valueKey]));
      },
    },
  };
</script>
