<!-- anchor-schedule -->
<template>
  <div class="anchor-schedule">
    <!-- 查询表单 -->
    <div class="anchor-schedule-form">
      <el-form
        :model="model"
        inline
        size="small"
        label-width="70px"
        @submit.native.prevent
      >
        <el-form-item label="首播日期:">
          <el-date-picker
            v-model="model.scheduleTimeRange"
            type="daterange"
            value-format="yyyy-MM-dd"
            start-placeholder="请选择"
            end-placeholder="请选择"
          />
        </el-form-item>

        <el-form-item label="直播平台:">
          <live-platform v-model="model.platformId" />
        </el-form-item>

        <el-form-item label="合作方式:">
          <cooperation-type v-model="model.cooperationType" />
        </el-form-item>

        <el-form-item label="游戏名称:">
          <game-name v-model="model.gameId" />
        </el-form-item>

        <el-form-item label="合作状态:">
          <cooperation-status
            v-model="model.status"
            :exclude-status="cooperationStatusEnum.signed"
          />
        </el-form-item>

        <el-form-item label="搜索:">
          <el-input
            v-model.trim="model.keyword"
            placeholder="主播名称、直播间号、商务"
            clearable
            @keyup.native.enter="handleFormSearchClick"
          />
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="searching"
            @click="handleFormSearchClick"
            >查询
          </el-button>

          <el-button icon="el-icon-refresh" @click="handleFormResetClick"
            >重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 操作栏 -->
    <div class="anchor-schedule-operate">
      <el-button
        v-if="isCurrUserDataPermissionAllOrGroup"
        type="primary"
        icon="el-icon-plus"
        size="small"
        @click="handleOperateAddClick"
        >新增
      </el-button>

      <el-button
        v-if="isCurrUserDataPermissionAllOrGroup"
        type="danger"
        icon="el-icon-delete"
        size="small"
        :disabled="!batchIds.length"
        @click="handleOperateRemoveClick"
        >删除
      </el-button>

      <el-button
        type="success"
        icon="el-icon-s-promotion"
        size="small"
        :disabled="!batchIds.length"
        @click="handleOperatePublishClick"
        >投放
      </el-button>

      <el-button
        type="warning"
        icon="el-icon-finished"
        size="small"
        :disabled="!batchIds.length"
        @click="handleOperateFinishClick"
        >结束
      </el-button>
    </div>

    <!-- 数据表格 -->
    <div class="anchor-schedule-table">
      <el-table
        v-loading="loading"
        ref="table"
        :data="schedules"
        size="small"
        @selection-change="handleTableSelectionChange"
      >
        <el-table-column type="selection" width="55" />

        <el-table-column
          label="主播名称"
          prop="anchorName"
          show-overflow-tooltip
        >
          <template v-slot="{ row: { anchorName } }">
            <div
              class="table-anchor-name"
              @click="handleTableRowAnchorNameClick(anchorName)"
            >
              {{ anchorName }}
            </div>
          </template>
        </el-table-column>

        <el-table-column label="首播时间" prop="firstShowTime" width="160px" />

        <el-table-column label="直播平台" prop="platformName" />

        <el-table-column label="游戏名称" prop="gameName" />

        <el-table-column label="签约天数" prop="days" />

        <el-table-column label="推广链接名">
          <template v-slot="{ row: { promoteLink } }">
            {{ promoteLink || '-' }}
          </template>
        </el-table-column>

        <el-table-column label="QQ群">
          <template v-slot="{ row: { contactNo } }">
            {{ contactNo || '-' }}
          </template>
        </el-table-column>

        <el-table-column label="带玩运营">
          <template v-slot="{ row: { playOperatorName } }">
            {{ playOperatorName || '-' }}
          </template>
        </el-table-column>

        <el-table-column label="跟进运营">
          <template v-slot="{ row: { operatorName } }">
            {{ operatorName || '-' }}
          </template>
        </el-table-column>

        <el-table-column label="商务" prop="username" />

        <el-table-column label="状态">
          <template v-slot="{ row: { status } }">
            <el-tag :type="status | cooperationStatusTagType" size="mini">
              {{ status | cooperationStatusText }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column label="投放时间" width="160px">
          <template v-slot="{ row: { publishTime } }">
            {{ publishTime || '-' }}
          </template>
        </el-table-column>

        <el-table-column label="创建时间" prop="createTime" width="160px" />

        <el-table-column label="操作" width="125px">
          <template v-slot="{ row }">
            <div class="table-row-operate">
              <el-button
                v-if="row.status !== cooperationStatusEnum.completed"
                icon="el-icon-edit"
                circle
                size="mini"
                @click="handleTableRowEditClick(row)"
              />

              <el-button
                v-if="
                  row.status === cooperationStatusEnum.scheduled &&
                  isCurrUserDataPermissionAllOrGroup
                "
                plain
                type="danger"
                icon="el-icon-delete"
                circle
                size="mini"
                @click="handleTableRowRemoveClick(row)"
              />

              <el-button
                v-if="
                  row.status === cooperationStatusEnum.scheduled &&
                  (isCurrUserDataPermissionAllOrGroup ||
                    row.userId === currentUserId)
                "
                plain
                type="success"
                icon="el-icon-s-promotion"
                circle
                size="mini"
                @click="handleTableRowPublishClick(row)"
              />

              <el-button
                v-if="
                  row.status === cooperationStatusEnum.published &&
                  (isCurrUserDataPermissionAllOrGroup ||
                    row.userId === currentUserId)
                "
                plain
                type="warning"
                icon="el-icon-finished"
                circle
                size="mini"
                @click="handleTableRowFinishClick(row)"
              />
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页器 -->
    <div class="anchor-schedule-pagination">
      <el-pagination
        :total="total"
        :current-page="pageNo"
        :page-sizes="pageSizes"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handlePageNoChange"
        @size-change="handlePageSizeChange"
      />
    </div>

    <!-- 新增/编辑排期对话框 -->
    <schedule-edit
      v-model="showEditDialog"
      @on-ok="handleScheduleEditDialogOkClick"
    />

    <!-- 删除排期对话框 -->
    <schedule-remove
      v-model="showRemoveDialog"
      :ok-button-loading="removing"
      @on-close="handleScheduleRemoveDialogClose"
      @on-ok="handleScheduleRemoveDialogOkClick"
    />

    <!-- 投放排期对话框 -->
    <schedule-remove
      v-model="showPublishDialog"
      content="确定要投放该排期吗？若确定，则会【立即】记录财务支出，并【不再允许】调整排期的主播人选。"
      content-icon-color="#E6A23C"
      ok-button-type="warning"
      :ok-button-loading="publishing"
      @on-close="handleSchedulePublishDialogClose"
      @on-ok="handleSchedulePublishDialogOkClick"
    />

    <!-- 结束排期对话框 -->
    <schedule-remove
      v-model="showFinishDialog"
      content="确定要结束本次合作吗？若确定，则会【立即】计算本次合作的 ROI，并完成该主播的【归属绑定】。"
      content-icon-color="#E6A23C"
      ok-button-type="warning"
      :ok-button-loading="finishing"
      @on-close="handleScheduleFinishDialogClose"
      @on-ok="handleScheduleFinishDialogOkClick"
    />
  </div>
</template>

<script>
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
  import LivePlatform from '../../../components/live-platform';
  import GameName from '../../../components/game-name';
  import CooperationType from '../../../components/cooperation-type';
  import CooperationStatus from '../../../components/cooperation-status';
  import { pageSize, pageSizes } from '../../../config/pagination';
  import recountPageNo from '../../../lib/recount-page-no';
  import cooperationStatus from '../../../enum/cooperation-status.js';
  import roleDataPermission from '../../../enum/role-data-permission.js';
  import {
    removeSchedules,
    publishSchedules,
    finishSchedules,
  } from '../../../api/anchor/schedule';

  export default {
    name: 'anchor-schedule',
    components: {
      LivePlatform,
      GameName,
      CooperationType,
      CooperationStatus,
      ScheduleEdit: () =>
        import(/* webpackChunkName: 'schedule-edit' */ './schedule-edit'),
      ScheduleRemove: () =>
        import(
          /* webpackChunkName: 'schedule-remove' */ '../../../components/confirm'
        ),
    },
    filters: {
      // 合作状态标签类型
      cooperationStatusTagType(val) {
        const typeMap = {
          [cooperationStatus.enum.signed]: 'info',
          [cooperationStatus.enum.scheduled]: 'primary',
          [cooperationStatus.enum.published]: 'warning',
          [cooperationStatus.enum.completed]: 'success',
        };

        return typeMap[val];
      },
      // 合作状态文案
      cooperationStatusText(val) {
        return cooperationStatus.map[val] || '';
      },
    },
    data() {
      return {
        model: null,
        pageNo: 1,
        pageSize,
        pageSizes,
        // 当前操作的排期签约 id
        operateId: '',
        showEditDialog: false,
        batchIds: [],
        showRemoveDialog: false,
        showPublishDialog: false,
        showFinishDialog: false,
        loading: false,
        searching: false,
        removing: false,
        publishing: false,
        finishing: false,
      };
    },
    computed: {
      ...mapState(['userInfo']),
      ...mapState('anchor/schedule', ['schedules', 'total']),
      ...mapGetters(['currentUserId']),

      // 当前登录用户的数据权限是否是'全部'或'分组'
      isCurrUserDataPermissionAllOrGroup() {
        const dataPermission = this.userInfo?.dataPermission;

        return (
          dataPermission === roleDataPermission.enum.all ||
          dataPermission === roleDataPermission.enum.group
        );
      },
      // 合作状态枚举值
      cooperationStatusEnum() {
        return cooperationStatus.enum;
      },
    },
    created() {
      this.initModel();
    },
    mounted() {
      this.getTableData();
    },
    beforeDestroy() {
      this.mttScheduleSignId();
    },
    methods: {
      ...mapMutations('anchor/schedule', ['mttScheduleSignId']),
      ...mapActions('anchor/schedule', ['actSchedules']),

      // 初始化模型
      initModel() {
        this.model = {
          scheduleTimeRange: [],
          platformId: '',
          gameId: '',
          cooperationType: '',
          status: '',
          keyword: '',
        };
      },
      // 获取表格数据
      async getTableData() {
        const { scheduleTimeRange } = this.model;
        const [startTime = '', endTime = ''] = scheduleTimeRange || [];

        this.loading = true;

        const success = await this.actSchedules({
          ...this.model,
          startTime,
          endTime,
          scheduleTimeRange: undefined,
          pageNo: this.pageNo,
          pageSize: this.pageSize,
        });

        this.loading = false;

        return success;
      },
      // 删除表格数据
      async removeTableData() {
        const ids = this.batchIds;

        if (!ids.length) return;

        this.removing = true;

        const res = await removeSchedules(ids);

        this.removing = false;

        return !!res;
      },
      // 投放表格数据
      async publishTableData() {
        const ids = this.batchIds;

        if (!ids.length) return;

        this.publishing = true;

        const res = await publishSchedules({ signIds: ids });

        this.publishing = false;

        return !!res;
      },
      // 结束表格数据
      async finishTableData() {
        const ids = this.batchIds;

        if (!ids.length) return;

        this.finishing = true;

        const res = await finishSchedules({ signIds: ids });

        this.finishing = false;

        return !!res;
      },
      // 表单查询按钮单击
      async handleFormSearchClick() {
        this.pageNo = 1;
        this.searching = true;

        await this.getTableData();

        this.searching = false;
      },
      // 表单重置按钮单击
      handleFormResetClick() {
        this.pageNo = 1;

        this.initModel();
        this.getTableData();
      },
      // 操作栏新增按钮单击
      handleOperateAddClick() {
        this.operateId = '';
        this.showEditDialog = true;
      },
      // 操作栏删除按钮单击
      handleOperateRemoveClick() {
        const batchIds = this.batchIds;
        const matchedSchedules = this.schedules.filter(({ signId }) =>
          batchIds.includes(signId)
        );

        for (let schedule of matchedSchedules) {
          const { userId, status } = schedule;

          if (
            !this.isCurrUserDataPermissionAllOrGroup &&
            userId !== this.currentUserId
          ) {
            this.$message.error('只能删除【自己】的数据');

            return;
          }

          if (status !== cooperationStatus.enum.scheduled) {
            this.$message.error('只能删除状态为【已排期】的数据');

            return;
          }

          continue;
        }

        this.showRemoveDialog = true;
      },
      // 操作栏投放按钮单击
      handleOperatePublishClick() {
        const batchIds = this.batchIds;
        const matchedSchedules = this.schedules.filter(({ signId }) =>
          batchIds.includes(signId)
        );

        for (let schedule of matchedSchedules) {
          const { userId, status } = schedule;

          if (
            !this.isCurrUserDataPermissionAllOrGroup &&
            userId !== this.currentUserId
          ) {
            this.$message.error('只能投放【自己】的数据');

            return;
          }

          if (status !== cooperationStatus.enum.scheduled) {
            this.$message.error('只能投放状态为【已排期】的数据');

            return;
          }

          continue;
        }

        this.showPublishDialog = true;
      },
      // 操作栏结束按钮单击
      handleOperateFinishClick() {
        const batchIds = this.batchIds;
        const matchedSchedules = this.schedules.filter(({ signId }) =>
          batchIds.includes(signId)
        );

        for (let schedule of matchedSchedules) {
          const { userId, status } = schedule;

          if (
            !this.isCurrUserDataPermissionAllOrGroup &&
            userId !== this.currentUserId
          ) {
            this.$message.error('只能结束【自己】的数据');

            return;
          }

          if (status !== cooperationStatus.enum.published) {
            this.$message.error('只能结束状态为【已投放】的数据');

            return;
          }

          continue;
        }

        this.showFinishDialog = true;
      },
      // 表格选择项改变
      handleTableSelectionChange(rows) {
        this.batchIds = rows.map(row => row.signId);
      },
      // 表格行主播名称单击
      handleTableRowAnchorNameClick(anchorName) {
        if (!anchorName) return;

        this.$router.push({
          name: 'library-anchor',
          query: { keyword: encodeURIComponent(anchorName) },
        });
      },
      // 表格行编辑按钮单击
      handleTableRowEditClick(row) {
        const { signId } = row;

        this.operateId = signId;
        this.showEditDialog = true;
      },
      // 表格行删除按钮单击
      handleTableRowRemoveClick(row) {
        const { signId } = row;

        this.batchIds = [signId];
        this.showRemoveDialog = true;
      },
      // 表格行投放按钮单击
      handleTableRowPublishClick(row) {
        const { signId } = row;

        this.batchIds = [signId];
        this.showPublishDialog = true;
      },
      // 表格行结束按钮单击
      handleTableRowFinishClick(row) {
        const { signId } = row;

        this.batchIds = [signId];
        this.showFinishDialog = true;
      },
      // 新增/编辑排期对话框确定按钮执行成功
      handleScheduleEditDialogOkClick() {
        this.getTableData();
      },
      // 删除对话框关闭
      handleScheduleRemoveDialogClose() {
        this.batchIds = [];

        this.$refs.table.clearSelection();
      },
      // 删除排期对话框确定按钮单击
      async handleScheduleRemoveDialogOkClick() {
        const success = await this.removeTableData();

        if (!success) return;

        this.showRemoveDialog = false;
        this.pageNo = recountPageNo(
          this.total,
          this.pageNo,
          this.pageSize,
          this.batchIds.length
        );

        this.getTableData();
      },
      // 投放对话框关闭
      handleSchedulePublishDialogClose() {
        this.batchIds = [];

        this.$refs.table.clearSelection();
      },
      // 投放排期对话框确定按钮单击
      async handleSchedulePublishDialogOkClick() {
        const success = await this.publishTableData();

        if (!success) return;

        this.showPublishDialog = false;

        this.getTableData();
      },
      // 结束对话框关闭
      handleScheduleFinishDialogClose() {
        this.batchIds = [];

        this.$refs.table.clearSelection();
      },
      // 结束排期对话框确定按钮单击
      async handleScheduleFinishDialogOkClick() {
        const success = await this.finishTableData();

        if (!success) return;

        this.showFinishDialog = false;

        this.getTableData();
      },
      // 分页器页码改变
      handlePageNoChange(pageNo) {
        this.pageNo = pageNo;

        this.getTableData();
      },
      // 分页器条目数改变
      handlePageSizeChange(pageSize) {
        this.pageSize = pageSize;

        this.getTableData();
      },
    },
    watch: {
      operateId(n, o) {
        this.mttScheduleSignId(n);
      },
    },
  };
</script>

<style scoped lang="scss">
  .anchor-schedule {
    // 查询表单
    .anchor-schedule-form {
      .el-form-item {
        margin-right: 70px;
      }

      .el-input,
      .el-select,
      .el-date-editor {
        width: 220px;
      }
    }

    // 数据表格
    .anchor-schedule-table {
      margin-top: 20px;

      // 主播名称
      .table-anchor-name {
        color: #409eff;
        text-decoration: underline;
        cursor: pointer;
      }

      // 操作按钮
      .table-row-operate {
        .el-button {
          min-width: auto;
        }
      }
    }

    // 分页器
    .anchor-schedule-pagination {
      margin-top: 20px;
    }
  }
</style>
