// 合作方式
export default {
  map: {
    1: '官单',
    2: '私单',
  },
  enum: {
    official: 1,
    unofficial: 2,
  },
};
